import React, { useState } from "react";
import styled from "styled-components";
import { SinisterPdf } from "../SinisterPdf";
import { resizeImage } from "../../helpers/Resizer";

const FileUpload = ({
  selectedFile,
  setSelectedFile,
  accept = ".jpg, .jpeg, .png, .webp",
}) => {
  const [file, setFile] = useState();
  const [isPDF, setIsPDF] = useState(false);

  const onFileChange = async (event) => {
    const file = event.target.files[0];

    //alert("CSH onFileChange : ");
    console.log("CSH onFileChange : ");

    if (file) {
      let resizedFile = file;

      if (file.type !== "application/pdf") {
        resizedFile = await resizeImage(file);
        console.log(resizedFile);
      }

      setFile(resizedFile);
      setIsPDF(resizedFile.type === "application/pdf");

      const reader = new FileReader();

      reader.onloadend = (e) => {
        setSelectedFile(e.target.result);
      };

      reader.readAsDataURL(resizedFile);
    }
  };

  return (
    <div>
      <ImageInputContainer>
        <input
          style={{ display: "none" }}
          type="file"
          accept={accept}
          multiple
          onChange={onFileChange}
        />
        Clique para selecionar imagem do veículo
      </ImageInputContainer>
      {selectedFile && (
        <div style={{ maxWidth: "700px" }}>
          {isPDF && <SinisterPdf fileName={file.name} />}
          {!isPDF && <img src={selectedFile} alt="Imagem selecionada" />}
        </div>
      )}
    </div>
  );
};

export default FileUpload;

const ImageInputContainer = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #9fa5b3;
  padding: 60px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: #9fa5b3;
  text-align: center;

  &:hover {
    border: 2px dashed black;
    color: black;
  }
`;
