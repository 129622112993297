import React, { useState, useEffect } from "react";
import styled from "styled-components";
import useForm from "react-hook-form";
import Lockr from "lockr";

import { FireWebToCase, GetFireWebToCase } from "../../../services/SalesForceService";
import file_upload from "../../../assets/icons/file_upload.svg";
import check_icon from "../../../assets/icons/check.svg";
import { PutAuxiliaryDocuments } from "../../../services/AuxiliaryDocuments";

const Form = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const [clientId, setClientId] = useState("");
  const [contractId, setContractId] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [fileBase64, setFileBase64] = useState("");
  const [fileType, setFileType] = useState("");

  const phone = props.values.contactPhone;
  const onSubmit = async (data) => {
    try {
      let CaseNumber

      const TipoFoto = {
        Id: "ocorrido",
        Titulo: "Ocorrido",
        TituloGrupo: "Documentação",
        Icone: "documentos",
        Obrigatorio: false,
        Visivel: true,
      };
      const Foto = {
        Tipo: "ocorrido",
        DataHoraUpload: new Date(),
        Caso: "",
        Contrato: contractId,
        Formato: fileType,
        Url: fileBase64,
      };
      const reason = "Contestação de Fatura";
      const subject = "Contestação de Fatura";
      const description = `Telefone para contato: ${phone}, Motivo da contestação: ${props.values.description}`

      const formData = {
        reason,
        subject,
        "00N6f00000Febwz": contractId,
        "00NN5000001Lr7C": clientId,
        description,
      };

      delete data["registrationDataCheck-0"];

      const formDataJson = JSON.stringify(formData);
      const formFileDataJson = JSON.stringify(fileBase64);

      props.values.setLoading(true);
      await FireWebToCase(JSON.parse(formDataJson));

      const response = await GetFireWebToCase(contractId);

      const filteredResponse = response.filter(
        (item) => item.Status === "New" && item.Reason === reason
      );

      if (filteredResponse.length > 0) {
        const selectedCase = filteredResponse[0];
        CaseNumber = selectedCase.CaseNumber;

        await PutAuxiliaryDocuments(
          fileBase64,
          CaseNumber,
          'contestacao',
          TipoFoto.Id,
          'jpg'
        );

      }
      props.values.setLoading(false);
      props.proceed();
    } catch (error) {
      console.error("Ocorreu um erro ao enviar os dados:", error);
      props.values.setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setSelectedFile(fileUrl);

      const fileExtension = file.name.split(".").pop();
      setFileType(fileExtension);

      const img = new Image();
      img.src = fileUrl;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const jpgDataUrl = canvas.toDataURL('image/jpeg');

        const base64String = jpgDataUrl.split(",")[1];
        setFileBase64(base64String);

      };
      img.onerror = (error) => {
        console.error("Error loading image:", error);
      };

      props.handleChangeFile(e);
    }
  };

  useEffect(() => {
    async function loadData() {
      let value;
      let clientData;

      if (props.nCtrId) {
        clientData = Lockr.get("ClientData");

        value = clientData.customerCars.filter((contracts) => {
          return contracts.contractInfo.nCtrId === props.nCtrId;
        });

        Lockr.set("ClientData", {
          IdCustomerSelectedCar: value[0].value,
          customerSelectedCar: value[0],
        });

        const customerSelectedCar = clientData.customerSelectedCar;
        const contractInfo = customerSelectedCar.contractInfo;
        setClientId(contractInfo.bpName);
        setContractId(contractInfo.nCtrId);
      } else {
        clientData = Lockr.get("ClientData");
        const customerSelectedCar = clientData.customerSelectedCar;
        const contractInfo = customerSelectedCar.contractInfo;
        setClientId(contractInfo.bpName);
        setContractId(contractInfo.nCtrId);
      }
    }

    loadData();
  }, [props.nCtrId]);

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <label>
            <span className="text-Ivoce">DESCREVA SEU CASO</span>
            <textarea
              placeholder="Texto descritivo feito pelo cliente sobre o ocorrido."
              name="descriptionCheck"
              value={props.values.description.value}
              onChange={(e) => props.handleChange(e)}
              ref={register({ required: true })}
              className={
                errors.anotherMotive
                  ? "loovi-field loovi-field-error"
                  : "loovi-field"
              }
            />
            {errors.descriptionCheck && (
              <span className="loovi-msg-error">Insira o motivo</span>
            )}
          </label>
          <label>
            <span className="text-Ivoce">PRINT DA CONTESTAÇÃO</span>
            <div className="form-group">
              {selectedFile.length === 0 || selectedFile === undefined ? (
                <div className="upload-file">
                  <p>Arquivo Selecionado</p> <img src={file_upload} />
                </div>
              ) : (
                <div className="upload-file selected-file">
                  <p>Comprovante</p> <img src={check_icon} />
                </div>
              )}
              <input type="file" onChange={handleFileChange} />
            </div>
          </label>
        </>
        <input type="submit" value="Enviar solicitação" />
      </form>
    </React.Fragment>
  );
};

export class FormInvoiceDispute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      description: "",
      file: "",
    };
  }

  proceed = () => {
    this.setState({ step: 2 });
  };

  handleChange = (event) => {
    this.setState({ description: event.target.value });
  };

  handleChangeFile = (event) => {
    this.setState({ file: event.target.value });
  };

  render() {
    const { step, description, file } = this.state;
    const { contactPhone, setLoading, proceed } = this.props;
    const values = {
      step,
      description,
      file,
      contactPhone,
      setLoading,
    };

    return (
      <Section>
        <Form
          values={values}
          proceed={proceed}
          handleChange={this.handleChange}
          handleChangeFile={this.handleChangeFile}
        />
      </Section>
    );
  }
}

const Section = styled.section`
  position: relative;
  font-size: 15px;
  color: #393043 !important;

  p {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.32px;
    line-height: 21px;
  }

  h4 {
    font-size: 15px;
    color: #899299;
    letter-spacing: -0.32px;
    text-align: center;
    line-height: 21px;
  }

  .loovi-descktop-padding {
    h3,
    h4 {
      text-align: left;
    }
  }

  text-color-Ivoce {
    color: #393043 !important;
  }

  input[type="file" i] {
    background-color: transparent;
    color: transparent;
  }

  input::file-selector-button {
    background: transparent;
    color: transparent;
    border: none;
    z-index: 1;
    position: relative;
  }

  .form-group {
    height: 100%;
    widht: 100%;
  }

  .upload-file {
    z-index: 3;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    height: 50px;
    border-radius: 10px;
    border: solid 2px #f2f3f8;
    width: 100%;
    padding: 16px 10px;
  }

  .upload-file p {
    color: #a1a4ab !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
  }

  .upload-file img {
    width: 18px;
    height: 18px;
  }

  .selected-file {
    border: none;
    background-color: #5a78ff;
    color: #ffffff;
    height: 34px;
    border-radius: 4px;
    width: 100%;
    padding: 8px 12px;
    font-size: 15px;
    z-index: 3;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 400;
  }

  .selected-file p {
    color: #ffffff !important;
    font-weight: 400 !important;
  }
`;
