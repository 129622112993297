import React from "react";

import "./style.scss";

export function SinisterPdf({ fileName = "", url = "#" }) {
  return (
    <a className="c_sinister_pdf" href={url} target="_blank" rel="noopener noreferrer">
      {fileName}
    </a>
  );
}
