import React, { useState, useEffect } from "react";
import styled from "styled-components";
import useForm from "react-hook-form";
import * as masks from "../../../helpers/masks";
import Lockr from "lockr";

import { FireWebToCase } from "../../../services/SalesForceService";
import alert_icon from "../../../assets/icons/alert_icon.svg";

import VehiclesService from "../../../services/Vehicles";

const Form = (props) => {
  const { register, handleSubmit, errors, clearError } = useForm();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [clientInfo, setClientInfo] = useState("");
  const [clientId, setClientId] = useState("");
  const [contractId, setContractId] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [data, setData] = useState({
    Ano: 0,
    Modelo: "",
    ModeloSanitizado: "",
    Fabricante: "",
    FabricanteSanitizado: "",
    Placa: "",
    DataHora: 0,
    Chassi: "",
    Cor: "",
    TipoVeiculo: "",
    QntPassageiros: 0,
    Fipe: {
      Codigo: "",
      Modelo: "",
      Valor: "",
    },
    AnoModelo: 0,
    Combustivel: "",
    Permitido: true,
  });
  useEffect(() => {
    async function loadData() {
      let value;
      let clientData;

      if (props.nCtrId) {
        clientData = Lockr.get("ClientData");

        value = clientData.customerCars.filter((contracts) => {
          return contracts.contractInfo.nCtrId === props.nCtrId;
        });

        Lockr.set("ClientData", {
          IdCustomerSelectedCar: value[0].value,
          customerSelectedCar: value[0],
        });

        setIformationClientData(clientData);
      } else {
        clientData = Lockr.get("ClientData");
        setIformationClientData(clientData);
      }
    }

    loadData();
  }, [props.nCtrId]);

  const phone = props.values.contactPhone;
  const onSubmit = async (data) => {
    try {
      const reason = "Troca de Veículo/ Correção de Placa";
      const subject = "Troca de Veículo/ Correção de Placa";
      const description = `Telefone para contato: ${phone}, Caso: ${props.values.changeCar}, Dados do veículo: { Placa: ${props.values.changesCarFields.plate}, Marca: ${props.values.changesCarFields.brand}, Ano: ${props.values.changesCarFields.year}, Modelo: ${props.values.changesCarFields.model}}, Fipe: ${props.values.fipeType.value}, Tipo de veiculo: ${props.values.vehicleType.value}`;

      const formData = {
        reason,
        subject,
        "00N6f00000Febwz": contractId,
        "00NN5000001Lr7C": clientId,
        description,
      };

      delete data["registrationDataCheck-0"];

      const formDataJson = JSON.stringify(formData);

      props.values.setLoading(true);

      await FireWebToCase(JSON.parse(formDataJson));

      props.values.setLoading(false);
      props.proceed();
    } catch (error) {
      console.error("Ocorreu um erro ao enviar os dados:", error);
      props.values.setLoading(false);
    }
  };

  const load = async (plate) => {
    const result = await VehiclesService.getVehicleByPlate(
      plate,
      clientInfo.possuiDispositivo
    );

    if (result) {
      setData(result);

      const fipeRange = mapFipeValueToRange(result.Fipe.Valor);
      props.handleSelectChangeFipeVehicle(
        { value: fipeRange, label: fipeRange },
        "fipeType"
      );

      props.handleChangeCar({
        target: {
          name: "plate",
          value: plate,
        },
      });

      props.handleChangeCar({
        target: {
          name: "brand",
          value: result.Fabricante,
        },
      });

      props.handleChangeCar({
        target: {
          name: "year",
          value: result.Ano,
        },
      });

      props.handleChangeCar({
        target: {
          name: "model",
          value: result.Modelo,
        },
      });
    }
  };

  const mapFipeValueToRange = (fipeValue) => {
    const ranges = [
      { min: 10000, max: 20000, label: "R$10.000,00 a R$20.000,00" },
      { min: 21000, max: 30000, label: "R$21.000,00 a R$30.000,00" },
      { min: 31000, max: 40000, label: "R$31.000,00 a R$40.000,00" },
      { min: 41000, max: 50000, label: "R$41.000,00 a R$50.000,00" },
      { min: 51000, max: 60000, label: "R$51.000,00 a R$60.000,00" },
      { min: 61000, max: 70000, label: "R$61.000,00 a R$70.000,00" },
      { min: 71000, max: 80000, label: "R$71.000,00 a R$80.000,00" },
      { min: 81000, max: 90000, label: "R$81.000,00 a R$90.000,00" },
      { min: 91000, max: 100000, label: "R$91.000,00 a R$100.000,00" },
      { min: 101000, max: 110000, label: "R$101.000,00 a R$110.000,00" },
      { min: 111000, max: 120000, label: "R$111.000,00 a R$120.000,00" },
      { min: 121000, max: 130000, label: "R$121.000,00 a R$130.000,00" },
      { min: 131000, max: 140000, label: "R$131.000,00 a R$140.000,00" },
      { min: 141000, max: 150000, label: "R$141.000,00 a R$150.000,00" },
    ];

    const numericValue = parseFloat(
      fipeValue.replace(/[R$\s.]/g, "").replace(",", ".")
    );
    const matchedRange = ranges.find(
      (range) => numericValue >= range.min && numericValue <= range.max
    );

    return matchedRange ? matchedRange.label : "";
  };

  const setIformationClientData = async (clientData) => {
    const customerSelectedCar = clientData.customerSelectedCar;
    const contractInfo = customerSelectedCar.contractInfo;
    setClientInfo(contractInfo);
    const initialValue = contractInfo.salesContractItems.reduce(
      (total, item) => {
        if (item.itemCode !== "SRV_ADESAO") {
          return total + item.unitPrice;
        }
        return total;
      },
      0
    );
    setUnitPrice(initialValue);
    setClientId(contractInfo.bpName);
    setContractId(contractInfo.nCtrId);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label>
            <span className="text-color-Ivoce">CASO</span>
            <select
              name={`changeCar`}
              ref={register({ required: true })}
              className="loovi-select"
              value={props.values.changeCar.value}
              onChange={(e) => {
                const val = e.target.value;
                props.handleSelectChangeData(
                  { value: val, label: val },
                  "changeCar"
                );
                if (props.values.changeCar.value !== "")
                  return clearError(`changeCar`);
              }}
            >
              {props.values.changesCar.map((area, idx) => (
                <option key={idx} value={area.value}>
                  {area.label}
                </option>
              ))}
            </select>
            {errors.changeCar && (
              <span className="loovi-msg-error">Selecione uma opção</span>
            )}
          </label>
          {props.values.changeCar.value !== "" && (
            <>
              <div className="data-car">
                <p>Dados do veículo</p>
              </div>
              <label>
                <span className="text-color-Ivoce">PLACA</span>
                <input
                  type="text"
                  name={`plate`}
                  onChange={(e) => props.handleChangeCar(e)}
                  onBlur={(e) => load(e.target.value)}
                  ref={register({ required: true })}
                  className={
                    errors.plate
                      ? "loovi-props.values loovi-props.values-error"
                      : "loovi-props.values"
                  }
                />
                {errors.plate && (
                  <span className="loovi-msg-error">
                    Insira a placa do carro
                  </span>
                )}
              </label>
              <div className="changesCar-brand">
                <label>
                  <span className="text-color-Ivoce">MARCA</span>
                  <input
                    type="text"
                    name={`brand`}
                    value={props.values.changesCarFields.brand}
                    onChange={(e) => props.handleChangeCar(e)}
                    ref={register({ required: true })}
                    className={
                      errors.brand
                        ? "loovi-props.values loovi-props.values-error"
                        : "loovi-props.values"
                    }
                  />
                  {errors.brand && (
                    <span className="loovi-msg-error">
                      Insira a marca do carro
                    </span>
                  )}
                </label>
                <label>
                  <span className="text-color-Ivoce">ANO</span>
                  <input
                    type="text"
                    name={"year"}
                    value={props.values.changesCarFields.year}
                    onChange={(e) => props.handleChangeCar(e)}
                    ref={register({ required: true })}
                    className={
                      errors.year
                        ? "loovi-props.values loovi-props.values-error"
                        : "loovi-props.values"
                    }
                  />
                  {errors.year && (
                    <span className="loovi-msg-error">
                      Insira o ano do carro
                    </span>
                  )}
                </label>
              </div>
              <label>
                <span className="text-color-Ivoce">MODELO</span>
                <input
                  type="text"
                  name={"model"}
                  value={props.values.changesCarFields.model}
                  onChange={(e) => props.handleChangeCar(e)}
                  ref={register({ required: true })}
                  className={
                    errors.model
                      ? "loovi-props.values loovi-props.values-error"
                      : "loovi-props.values"
                  }
                />
                {errors.model && (
                  <span className="loovi-msg-error">Insira o modelo</span>
                )}
              </label>
            </>
          )}
          {props.values.changeCar.value === "Troca de Veículo" && (
            <>
              <label>
                <span className="text-color-Ivoce">TIPO DE VEÍCULO</span>
                <select
                  name={`vehicleType`}
                  ref={register({ required: true })}
                  className="loovi-select"
                  value={props.values.vehicleType.value}
                  onChange={(e) => {
                    const val = e.target.value;
                    props.handleSelectChangeTypeVehicle(
                      { value: val, label: val },
                      "vehicleType"
                    );
                    if (props.values.vehicleTypes.value !== "")
                      return clearError(`vehicleType`);
                  }}
                >
                  {props.values.vehicleTypes.map((area, idx) => (
                    <option key={idx} value={area.value}>
                      {area.label}
                    </option>
                  ))}
                </select>
                {errors.vehicleType && (
                  <span className="loovi-msg-error">Selecione uma opção</span>
                )}
              </label>
              <label>
                <span className="text-color-Ivoce">FIPE</span>
                <select
                  name="fipeType"
                  ref={register({
                    required: true,
                  })}
                  className="loovi-select"
                  value={props.values.fipeType.value}
                  onChange={(e) => {
                    const val = e.target.value;
                    props.handleSelectChangeFipeVehicle(
                      { value: val, label: val },
                      "fipeType"
                    );
                    if (val !== "") {
                      clearError("fipeType");
                    }
                  }}
                >
                  <option value="">Selecione uma opção</option>
                  {props.values.fipeTypes.map((area, idx) => (
                    <option key={idx} value={area.value}>
                      {area.label}
                    </option>
                  ))}
                </select>
                {(errors.fipeType || props.values.fipeType.value === '') && (
                  <span className="loovi-msg-error">Selecione uma opção</span>
                )}
              </label>
              {(data.Permitido === false ||
                props.values.fipeType.value ===
                  "R$150.000,00 a R$200.000,00") && (
                <div className="alert-mensagem">
                  <img src={alert_icon} />
                  <div className="alert-mensagem-text">
                    <p>
                      {
                        data.Permitido === false
                          ? "Veículo não aceito"
                          : props.values.fipeType.value ===
                              "R$150.000,00 a R$200.000,00" &&
                            `FIPE é superior a 150 mil`
                        /*?
                        : `Sua FIPE está divergente com seu plano atual. Para
                            prosseguir com a alteração será necessário atualizar
                            seu plano.`*/
                      }
                    </p>
                    {data.Permitido === false ? (
                      <div className="alert-description">
                        <p>
                          Lamentamos, mas no momento não podemos assegurar o
                          veículo selecionado conforme as políticas da nossa
                          empresa. Agradecemos a sua compreensão.
                        </p>
                      </div>
                    ) : (
                      props.values.fipeType.value ===
                        "R$150.000,00 a R$200.000,00" && (
                        <div className="alert-description">
                          <p>
                            Para prosseguir com a alteração do veículo, seu
                            plano será ajustado tendo as seguintes coberturas:
                          </p>
                          <ul>
                            <li>
                              Furto, Roubo e perda total (Cobertura limitada ao
                              valor de R$150.000,00)
                            </li>
                            <li>Assistência 24 Horas</li>
                            <li>Carro Reserva</li>
                          </ul>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
              {props.values.fipeType.value !== "" && (
                <div className="monthly-payment">
                  <p>Valor da assinatura atualizado:</p>
                  <div className="section-value">
                    <p className="value-payment">
                      R$ {unitPrice.toString().replace(".", ",")}
                    </p>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {props.values.changeCar.value !== "" && (
          <label className="checkbox">
            <input
              type="checkbox"
              checked={isCheckboxChecked}
              onChange={(e) => setIsCheckboxChecked(e.target.checked)}
            />
            <p>Estou de acordo com a alteração</p>
          </label>
        )}

        <input
          type="submit"
          value="Confirmar alteração"
          disabled={!isCheckboxChecked}
        />
      </form>
    </React.Fragment>
  );
};

export class FormVehicleChanges extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      showAdditionalField: false,
      changesCar: [
        { value: "", label: "-- Nenhum --" },
        { value: "Troca de Veículo", label: "Troca de Veículo" },
        { value: "Correção de Placa", label: "Correção de Placa" },
      ],
      changeCar: { value: "", label: "-- Nenhum --" },
      changesCarFields: {
        brand: "",
        plate: "",
        year: "",
        model: "",
      },
      fipeType: { value: "", label: "-- Nenhum --" },
      fipeTypes: [
        { value: "", label: "-- Nenhum --" },
        {
          value: "R$10.000,00 a R$20.000,00",
          label: "R$10.000,00 a R$20.000,00",
        },
        {
          value: "R$21.000,00 a R$30.000,00",
          label: "R$21.000,00 a R$30.000,00",
        },
        {
          value: "R$31.000,00 a R$40.000,00",
          label: "R$31.000,00 a R$40.000,00",
        },
        {
          value: "R$41.000,00 a R$50.000,00",
          label: "R$41.000,00 a R$50.000,00",
        },
        {
          value: "R$51.000,00 a R$60.000,00",
          label: "R$51.000,00 a R$60.000,00",
        },
        {
          value: "R$61.000,00 a R$70.000,00",
          label: "R$61.000,00 a R$70.000,00",
        },
        {
          value: "R$71.000,00 a R$80.000,00",
          label: "R$71.000,00 a R$80.000,00",
        },
        {
          value: "R$81.000,00 a R$90.000,00",
          label: "R$81.000,00 a R$90.000,00",
        },
        {
          value: "R$91.000,00 a R$100.000,00",
          label: "R$91.000,00 a R$100.000,00",
        },
        {
          value: "R$101.000,00 a R$110.000,00",
          label: "R$101.000,00 a R$110.000,00",
        },
        {
          value: "R$111.000,00 a R$120.000,00",
          label: "R$111.000,00 a R$120.000,00",
        },
        {
          value: "R$121.000,00 a R$130.000,00",
          label: "R$121.000,00 a R$130.000,00",
        },
        {
          value: "R$131.000,00 a R$140.000,00",
          label: "R$131.000,00 a R$140.000,00",
        },
        {
          value: "R$141.000,00 a R$150.000,00",
          label: "R$141.000,00 a R$150.000,00",
        },
      ],
      vehicleType: { value: "", label: "-- Nenhum --" },
      vehicleTypes: [
        { value: "", label: "-- Nenhum --" },
        { value: "Veículo leve", label: "Veículo leve" },
        {
          value: "Pick-up /  SUV  / Caminhonete",
          label: "Pick-up /  SUV  / Caminhonete",
        },
        { value: "Utilitário leve", label: "Utilitário leve" },
      ],
    };
  }

  proceed = () => {
    this.setState({ step: 2 });
  };

  handleSelectChangeData = (selectedOption) => {
    this.setState({ changeCar: selectedOption });
  };

  handleChangeCar = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      changesCarFields: {
        ...prevState.changesCarFields,
        [name]: value,
      },
    }));
  };

  handleSelectChangeTypeVehicle = (selectedOption) => {
    this.setState({ vehicleType: selectedOption });
  };

  handleSelectChangeFipeVehicle = (selectedOption) => {
    this.setState({ fipeType: selectedOption });
  };

  handleCheckboxChange = () => {
    this.setState((prevState) => ({
      showAdditionalField: !prevState.showAdditionalField,
    }));
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => {
      let updatedValue = value;
      return { [name]: updatedValue };
    });
  };

  render() {
    const {
      step,
      changesCar,
      changeCar,
      changesCarFields,
      fipeType,
      fipeTypes,
      vehicleType,
      vehicleTypes,
    } = this.state;
    const { contactPhone, setLoading, proceed } = this.props;
    const values = {
      step,
      changesCar,
      changeCar,
      changesCarFields,
      fipeType,
      fipeTypes,
      vehicleType,
      vehicleTypes,
      contactPhone,
      setLoading,
    };
    return (
      <Section>
        <Form
          values={values}
          proceed={proceed}
          handleSelectChangeData={this.handleSelectChangeData}
          handleCheckboxChange={this.handleCheckboxChange}
          handleChange={this.handleChange}
          handleChangeCar={this.handleChangeCar}
          handleSelectChangeTypeVehicle={this.handleSelectChangeTypeVehicle}
          handleSelectChangeFipeVehicle={this.handleSelectChangeFipeVehicle}
        />
      </Section>
    );
  }
}

const Section = styled.section`
  position: relative;
  font-size: 15px;

  p {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.32px;
    line-height: 21px;
  }

  h4 {
    font-size: 15px;
    color: #899299;
    letter-spacing: -0.32px;
    text-align: center;
    line-height: 21px;
  }

  .loovi-descktop-padding {
    h3,
    h4 {
      text-align: left;
    }
  }

  .changesCar-brand {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
  }

  .changesCar-brand input {
    width: 96% !important;
  }

  .text-color-Ivoce {
    color: #393043 !important;
  }

  .data-car p {
    font-weight: 700 !important;
    font-size: 15px;
    text-align: center;
    color: #a1a4ab;
    font-family: BrownStd-Light;
    margin-top: 1rem;
  }

  .alert-mensagem {
    display: flex;
    flex-direction: row;
    width: 100%;
    background: #ffefef;
    padding: 16px 12px;
    border-radius: 4px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .alert-mensagem img {
    width: 24px;
    height: 24px;
  }

  .alert-mensagem p {
    margin-left: 0.5rem;
    color: #000000;
    font-weight: 400;
    font-size: 14px;
  }

  .alert-mensagem-text {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .alert-description {
    margin-top: 0.5rem;
  }

  .alert-description p,
  .alert-description ul,
  .alert-description li {
    color: #515151;
    font-size: 12px;
    font-weight: 400;
  }

  .alert-description ul {
    margin-top: 0.5rem;
  }

  .monthly-payment {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 2.5rem;
  }

  .monthly-payment p {
    color: #a1a4ab;
    font-size: 14px;
    font-weight: 400;
  }

  .section-value {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .value-payment {
    color: #5a78ff !important;
    font-size: 36px !important;
    font-weight: 800 !important;
  }

  .checkbox {
    color: #a1a4ab !important;
    display: flex;
    align-items: center;
  }

  .checkbox p {
    margin-left: 0.5rem;
  }
`;
