import React, { useEffect, useState } from "react";
import Lockr from "lockr";
import { URLS, api_auth } from "../../helpers/Request";
import { SinisterImageUploader } from "../SinisterImageUploader";
import { LoaderInline } from "../LoaderInline";

export function SinisterImages({chamado}) {
  const [apiPhotos, setApiPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  async function loadData() {
    const clientData = Lockr.get("ClientData");
    setIsLoading(true);
    const result = await api_auth
      .get(
        `${URLS.FOTOS}/${clientData.customerSelectedCar.contractInfo.nCtrId}/${chamado}`
      )
      .json();
    setApiPhotos(result || []);
    setIsLoading(false);
  }

  useEffect(() => {
    async function loadFotos() {
      const clientData = Lockr.get("ClientData");
      setIsLoading(true);
      const result = await api_auth
        .get(
          `${URLS.FOTOS}/${clientData.customerSelectedCar.contractInfo.nCtrId}/${chamado}`
        )
        .json();
      setApiPhotos(result || []);
      setIsLoading(false);
    }
    if(chamado){
      console.log('chamado : ', chamado);
      loadFotos();
    }
  }, [chamado]);
  return (
    <div className="c_sinister_images">
      {!isLoading &&
        apiPhotos.map((apiPhoto) => (
          <SinisterImageUploader
            key={apiPhoto.TipoFoto.Id}
            details={apiPhoto}
            onPhotoFinishedUploading={loadData}
            chamado={chamado}
          />
        ))}
      {isLoading && <LoaderInline />}
    </div>
  );
}
